@keyframes topLeft {
  0% {
    transform: translateX(0px) translateY(0px);
  }
  20% {
    transform: translateX(-5px) translateY(-5px);
  }
  80% {
    transform: translateX(-5px) translateY(-5px);
  }
  100% {
    transform: translateX(0px) translateY(0px);
  }
}

@keyframes topRight {
  0% {
    transform: translateX(0px) translateY(0px);
  }
  20% {
    transform: translateX(5px) translateY(-5px);
  }
  80% {
    transform: translateX(5px) translateY(-5px);
  }
  100% {
    transform: translateX(0px) translateY(0px);
  }
}

@keyframes bottomLeft {
  0% {
    transform: translateX(0px) translateY(0px);
  }
  20% {
    transform: translateX(-5px) translateY(5px);
  }
  80% {
    transform: translateX(-5px) translateY(5px);
  }
  100% {
    transform: translateX(0px) translateY(0px);
  }
}

@keyframes bottomRight {
  0% {
    transform: translateX(0px) translateY(0px);
  }
  20% {
    transform: translateX(5px) translateY(5px);
  }
  80% {
    transform: translateX(5px) translateY(5px);
  }
  100% {
    transform: translateX(0px) translateY(0px);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  30% {
    transform: rotate(180deg);
  }
  70% {
    transform: rotate(220deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  background: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  opacity: 1;
  transition: 300ms;
}

.site-loaded {
  opacity: 0;
  z-index: -1;
}

.preloader-container {
  width: 75px;
  height: 75px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  transform: rotate(180deg);
  animation: rotate 3500ms infinite;
}

.preloader .preloader-container .preloader-box {
  width: 35px;
  height: 35px;
  background: var(--preloader-grey-color);
}

.preloader .preloader-container .preloader-box:nth-child(1) {
  width: 35px;
  height: 35px;
  background: var(--primary-green-color);
  border-radius: 6px 6px 0px 6px;
  animation: topLeft infinite linear 1500ms;
  animation-duration: 200;
}

.preloader .preloader-container .preloader-box:nth-child(2) {
  width: 35px;
  height: 35px;
  border-radius: 6px 6px 6px 0px;
  animation: topRight infinite linear 1500ms;
}

.preloader .preloader-container .preloader-box:nth-child(3) {
  width: 35px;
  height: 35px;
  border-radius: 6px 0px 6px 6px;
  animation: bottomLeft infinite linear 1500ms;
}

.preloader .preloader-container .preloader-box:nth-child(4) {
  width: 35px;
  height: 35px;
  background: var(--black-color);
  border-radius: 0px 6px 6px 6px;
  animation: bottomRight infinite linear 1500ms;
}
